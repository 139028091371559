import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import "../styles/dashboard/entry.scss";
// import swiper from "../javascript/vendor/swiper"
require("bootstrap");

import * as Fontawesome from "../javascript/vendor/fontawesome";
require.context("../images", true, /\.(png|jpg|jpeg|svg)$/);
import * as Dropify from "../javascript/vendor/dropify";

import Rails from "@rails/ujs";
Rails.start();

// Toastrの設定
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  timeout: 2000,
  preventDuplicates: true,
};
global.toastr = toastr;
require("../styles/dropify.scss");

require.context("../images", true, /\.(png|jpg|jpeg|svg)$/);

$(document).ready(() => {
  // Initialize Dropify
  Dropify.activate({
    messages: {
      default: `
        <p class="dropify-text">ここに写真をドロップ<br>または</p>
        <p class="btn-dropify">写真を選択</p>
      `,
      replace: '<p class="dropify-text">ここに写真をドロップ<br>または</p>',
      remove: "削除",
      error: "Error",
    },
  });

  // Handle remote links
  // $(document).on("click", 'a[data-remote="true"]', async (e) => {
  //   e.preventDefault();
  //   const $this = $(e.currentTarget);

  //   try {
  //     await $.ajax({
  //       url: $this.attr("href"),
  //       type: "GET",
  //     });
  //   } catch (error) {
  //     console.error("Ajax request failed:", error);
  //     toastr.error("リクエストに失敗しました");
  //   }
  // });
});
